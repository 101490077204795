<template>
  <div class="navbar-container">
    <div class="navbar">
      <div class="navbar-logo">
        <a href="#">
          <img src="https://bjyanboyuan.oss-cn-beijing.aliyuncs.com/ybySite/config/file/logo.png" alt="">
        </a>
      </div>
      <div class="navbar-actions">
        <ul class="menu">
          <li :class="['menu-item', $route.path.includes(item.path) ? 'active' : '']" v-for="(item, index) in menuList" :key="item.name">
            <a href="#" class="" @click.prevent="handleChangeMenu(item.path)">{{ item.name }}</a>
          </li>
        </ul>
        <div v-if="!isLoggedIn" class="user">
          <a href="#" @click.prevent="goToLogin">登录</a>
        </div>
        <div v-if="isLoggedIn" class="user-info">
          您好 {{ userInfo.nickName }}
          <a href="#" class="logout" @click.prevent="logout">退出</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userInfo: this.getUserInfo(),
      menuIndex: 0,
      menuList: [
        {
          name: '首页',
          path: '/home'
        },
        {
          name: '课程专栏',
          path: '/course'
        },
        {
          name: '教育资讯',
          path: '/news'
        },
        {
          name: '关于我们',
          path: '/about'
        }
      ],
      register: '/register'
    }
  },
  computed:{
    isLoggedIn() {
      return !!this.userInfo;
    }
  },
  methods: {
    handleChangeMenu (path) {
      this.menuIndex = path;
      this.$router.push({ path });
    },
    getUserInfo() {
      const userDataString = localStorage.getItem('userInfo');
      return userDataString ? JSON.parse(userDataString) : null;
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      // 重置userInfo状态或重定向到登录页
      this.userInfo = null;
      // 刷新页面
      window.location.reload();
    },
    goToLogin() {
      this.$router.push('/register');
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/global_font.scss';
.navbar-container{
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* 添加阴影 */
  .navbar{
    width: 100%;
    height: 95px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar-logo{
      a{
        display: flex;
        align-items: center;
        img{
          height: 48px;
        }
      }
    }
    .navbar-actions{
      display: flex;
      .menu{
        display: flex;
        .menu-item{
          a{
            display: block;
            padding: 0 15px;
            line-height: 95px;
            font-weight: 500;
          }
        }
      }
      .user{
        display: flex;
        align-items: center;
        a{
          width: 4rem;
          height: 2rem;
          font-weight: 500;
          line-height: 2rem;
          text-align: center;
          border-radius: 1rem;
          margin-left: 1.3rem;
        }
      }
      .user-info{
        display: flex;
        align-items: center;
        margin-left: 30px;
        .logout{
          padding-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
  @media (min-width: 1300px) {
    .navbar {
      width: 1270px;
    }
  }
}
</style>
