<template>
  <div class="loading-placeholder">
    <img src="../assets/images/loading.gif" alt="加载中..."/>
  </div>
</template>

<script>
export default {
  name: 'LoadingPlaceholder',
}
</script>

<style scoped>
.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-placeholder img {
  width: 50px;
  height: 50px;
}
</style>
