import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 路由重复跳转修复
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/course',
    name: 'Course',
    component: () => import('@/views/Course.vue'),
    redirect: '/course/index',
    children: [
      {
        path: 'index',
        name: 'CourseIndex',
        component: () => import('@/views/CourseIndex.vue'),
        meta: {
          title: '课程专栏'
        }
      },
      {
        path: 'apply/:id',
        name: 'CourseApply',
        component: () => import('@/views/CourseApply.vue'),
        meta: {
          title: '课程报名'
        }
      }
    ]
  },
  // {
  //   path: '/CourseIntroduction',
  //   name: 'CourseIntroduction',
  //   component: () => import('@/views/CourseIntroduction.vue'),
  //   meta: {
  //     title: '课业介绍'
  //   }
  // },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News.vue'),
    redirect: '/news/index',
    children: [
      {
        path: 'index',
        name: 'NewsIndex',
        component: () => import('@/views/NewsIndex.vue'),
        meta: {
          title: '教育资讯'
        }
      },
      {
        path: 'detail/:id',
        name: 'NewsDetail',
        component: () => import('@/views/NewsDetail.vue'),
        meta: {
          title: '资讯详情'
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
    meta: {
      title: '登陆',
      fullPage: true // 添加这个标记
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 } // 返回{x: 0, y: 0}表示每次都将页面滚动到顶部
  }
})

export default router
