<template>
<div class="copyright">
  <div class="copyright-container">
    <div class="copyright-top">
      <div class="address">
        <h3>北京燕博园教育科技有限公司</h3>
        <p>地址：北京市海淀区万寿路街道复兴路61号30号楼二层202室</p>
        <p>电话：010-88178508</p>
        <p>邮箱：yanboyuan67@qq.com</p>
      </div>
      <div class="qrcode">
        <img src="../assets/images/qrcode.png" alt="">
        <p>扫二维码加入公众号了解更多</p>
      </div>
    </div>
    <div class="copyright-bottom">Copyright ©2024 北京燕博园教育科技有限公司 ：京ICP备15061430号</div>
  </div>
</div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.copyright{
  height: 250px;
  background: #ffffffed;
  .copyright-container{
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #333;
    background: #ffffffed;
    margin: 0 auto;
    padding: 60px 120px 0;
    box-sizing: border-box;
    .copyright-top{
      display: flex;
      justify-content: space-between;
      .address{
        h3{
          font-size: 16px;
          margin-bottom: 10px;
          color: #333;
        }
      }
      .qrcode{
        text-align: center;
        img{
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
        }
        p{
          font-size: 10px;
        }
      }
    }

    .copyright-bottom{
      text-align: center;
      margin-top: 40px;
      color: #333;
    }
  }
}

</style>
