<template>
  <div id="app">
    <nav-bar v-if="!$route.meta.fullPage" />
    <!-- 使用v-if和v-else来控制显示 -->
    <div v-if="isLoading">
      <loading-placeholder/>
    </div>
    <router-view v-else/>
    <copyright v-if="!$route.meta.fullPage" />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Copyright from '@/components/Copyright.vue'
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue'
export default {
  components: { NavBar, Copyright, LoadingPlaceholder },
  data() {
    return {
      isLoading: true
    };
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.isLoading = true;
      next();
    });
    this.$router.afterEach(() => {
      this.isLoading = false;
    });
  }
}
</script>

<style lang="scss">
#app{
  background: #f2f2f2;
}
</style>
